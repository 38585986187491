import React from 'react'
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import RenderSlices from '../01 Atoms/slicesHelper'
import Layout from '../04 Templates/layout'
import Pagination from '../02 Molecules/pagination'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../06 Utilities/linkResolver'

export const query = graphql`
query InspirationPageQuery(
  $uid: String!
  $paginationPreviousUid: String!
  $paginationPreviousLang: String!
  $paginationNextUid: String!
  $paginationNextLang: String!
  ) {
    prismic {
        allInspirations(uid: $uid, sortBy: meta_firstPublicationDate_ASC) {
          edges {
            node {
              destination {
                ... on PRISMIC_Destination {
                  title
                  _meta {
                    id
                    uid
                    type
                    tags
                  }
                }
              }
              caption
              hero_image
              hero_imageSharp {
                childImageSharp {
                  fluid(maxWidth: 1900) {
                      ...GatsbyImageSharpFluid
                  }
                }
              }
              numeral
              introduction
              title
              _meta {
                tags
                uid
                type
              }
              body {
                ... on PRISMIC_InspirationBodyContent {
                  type
                  label
                  primary {
                    content
                  }
                }                
                ... on PRISMIC_InspirationBodyInline_image {
                  type
                  label
                  primary {
                    image
                    imageSharp {
                      childImageSharp {
                        fluid(maxWidth: 900) {
                            ...GatsbyImageSharpFluid
                        }
                      }
                    }                    
                    caption
                  }
                }                
              }
            }
          }
        }
        next: inspiration(uid: $paginationNextUid, lang: $paginationNextLang) {
              destination {
                ... on PRISMIC_Destination {
                  title
                  _meta {
                    id
                    uid
                    type
                    tags
                  }
                }
              }
              hero_image
              hero_imageSharp {
                childImageSharp {
                  fluid(maxWidth: 1900) {
                      ...GatsbyImageSharpFluid
                  }
                }
              }
              numeral
              introduction
              title
              _meta {
                tags
                uid
                type
              }
              body {
                ... on PRISMIC_InspirationBodyContent {
                  type
                  label
                  primary {
                    content
                  }
                }                
                ... on PRISMIC_InspirationBodyInline_image {
                  type
                  label
                  primary {
                    image
                    imageSharp {
                      childImageSharp {
                        fluid(maxWidth: 900) {
                            ...GatsbyImageSharpFluid
                        }
                      }
                    }                    
                    caption
                  }
                }                
              }
            }
        prev: inspiration(uid: $paginationPreviousUid, lang: $paginationPreviousLang) {
              destination {
                ... on PRISMIC_Destination {
                  title
                  _meta {
                    id
                    uid
                    type
                    tags
                  }
                }
              }
              hero_image
              hero_imageSharp {
                childImageSharp {
                  fluid(maxWidth: 1900) {
                      ...GatsbyImageSharpFluid
                  }
                }
              }
              numeral
              introduction
              title
              _meta {
                tags
                uid
                type
              }
              body {
                ... on PRISMIC_InspirationBodyContent {
                  type
                  label
                  primary {
                    content
                  }
                }                
                ... on PRISMIC_InspirationBodyInline_image {
                  type
                  label
                  primary {
                    image
                    imageSharp {
                      childImageSharp {
                        fluid(maxWidth: 900) {
                            ...GatsbyImageSharpFluid
                        }
                      }
                    }                    
                    caption
                  }
                }                
              }
            }
        all: allInspirations(first: 9999999, sortBy: meta_firstPublicationDate_ASC) {
          edges {
            node {
              destination {
                ... on PRISMIC_Destination {
                  title
                  _meta {
                    tags
                    uid
                    type
                  }
                }
              }
              numeral
              hero_image
              hero_imageSharp {
                childImageSharp {
                  fluid(maxWidth: 1900) {
                      ...GatsbyImageSharpFluid
                  }
                }
              }
              introduction
              title
              _meta {
                tags
                uid
                type
                lang
              }
              body {
                ... on PRISMIC_InspirationBodyContent {
                  type
                  label
                  primary {
                    content
                  }
                }                
                ... on PRISMIC_InspirationBodyInline_image {
                  type
                  label
                  primary {
                    image
                    imageSharp {
                      childImageSharp {
                        fluid(maxWidth: 900) {
                            ...GatsbyImageSharpFluid
                        }
                      }
                    }                    
                    caption
                  }
                }                
              }
            }        
          }
        }
      }
    }
`
 
  const RenderBody = ({ allDestinations, home, next, prev }) => (
    <React.Fragment>
      <article>
        <section className="homepage-slice-wrapper content-slice">
          <div className="wrapper">
          {home.introduction ? 
            <div className="introduction content">
              {RichText.render(home.introduction, linkResolver)}
            </div>
          : ""}
          </div>
        </section>
        <section className="homepage-slice-wrapper content-slice">
          <div className="wrapper">
            <div className="content-blocks content-blocks--image-block">
              <Img className="content-blocks--image-block__image" fluid={home.hero_imageSharp.childImageSharp.fluid} alt={home.hero_image.alt} />
              {home.caption && home.caption[0].text ? 
              <div className="caption">
                  {RichText.render(home.caption)}
              </div>
              : ""}
            </div>
          </div>
        </section>
        <RenderSlices slices={home} />
        <Pagination allDestinations={allDestinations} current={home} next={next} prev={prev} />
      </article>
    </React.Fragment>
  );
  
  const Inspiration = data => {
    
    const doc = data.data.prismic.allInspirations.edges.slice(0,1).pop();
    const allDestinations = data.data.prismic.all.edges;
    const next = data.data.prismic.next;
    const prev = data.data.prismic.prev;
    
    if(!doc) return null;
    return(
      <Layout allDestinations={allDestinations} data={doc} next={next} prev={prev}>
          <RenderBody allDestinations={allDestinations} home={doc.node} next={next} prev={prev} />
      </Layout>
    )
  }
  
  export default Inspiration;